import React, { useCallback, useRef, useMemo, useState, useEffect } from "react"
import {
  Stack,
  Image,
  Text,
  Grid,
  GridItem,
  Spacer,
  Button,
  Box,
} from "@chakra-ui/react"
import { RiMapPin2Fill } from "react-icons/ri"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import { getDistanceBetween2Points } from "../utils/geo"

import "leaflet/dist/leaflet.css"

const FacilitySpot = ({ map, property, facility, scrollToMap }: any) => {
  const { lat, lon } = property.propertyLocation

  const onClick = useCallback(() => {
    map.setView({ lat: facility.location.lat, lng: facility.location.lon }, 15)
    scrollToMap()
  }, [map, facility])

  return (
    <>
      {facility && (
        <GridItem w="100%">
          <Stack spacing={4}>
            <Image
              src={facility.featuredPhoto.file.url}
              alt={facility.name}
              h={64}
              objectFit={`cover`}
            />
            <Stack spacing={1} direction="row">
              <Stack spacing={1}>
                <Text fontSize={{ base: 13, lg: 14 }}>{facility.name}</Text>
                <Text fontSize={12}>{facility.description.description}</Text>
              </Stack>
              <Spacer />
              <Button
                onClick={onClick}
                size={`sm`}
                bg={`black`}
                color={`white`}
                position="relative"
                pr={8}
                rounded={0}
                _hover={{ bg: "blackAlpha.100", color: "blackAlpha.800" }}
              >
                <Text fontSize={12}>
                  物件から約
                  {Math.floor(
                    getDistanceBetween2Points(
                      { lat, lng: lon },
                      { lat: facility.location.lat, lng: facility.location.lon }
                    ) * 10
                  ) * 100}
                  m
                </Text>
                <Box position={`absolute`} right={2}>
                  <RiMapPin2Fill size={20} />
                </Box>
              </Button>
            </Stack>
          </Stack>
        </GridItem>
      )}
    </>
  )
}

const PropertySpot = ({ map, property, scrollToMap }: any) => {
  const { lat, lon } = property.propertyLocation

  const onClick = useCallback(() => {
    map.setView({ lat, lng: lon }, 16)
    scrollToMap()
  }, [map])

  return (
    <>
      {property && (
        <Button
          onClick={onClick}
          bg={`black`}
          size={`sm`}
          color={`white`}
          position="relative"
          pr={8}
          rounded={0}
          _hover={{ bg: "blackAlpha.100", color: "blackAlpha.800" }}
        >
          物件の場所を確認
          <Box position={`absolute`} right={2}>
            <RiMapPin2Fill size={20} />
          </Box>
        </Button>
      )}
    </>
  )
}

export const Map = ({ property }: any) => {
  const [map, setMap] = useState<any>(null)
  const scrollEndpoint: any = useRef(null)

  const { lat, lon } = property.propertyLocation

  const scrollToMap = useCallback(() => {
    scrollEndpoint.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }, [scrollEndpoint])

  if (typeof window !== "undefined") {
    const displayMap = useMemo(
      () => (
        <MapContainer
          center={{ lat, lng: lon }}
          zoom={15}
          whenCreated={setMap}
          style={{ height: "500px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[lat, lon]}
            icon={L.icon({
              iconUrl:
                "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
              iconRetinaUrl:
                "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
              iconSize: new L.Point(25, 35),
            })}
          >
            <Popup>{property.propertyName}</Popup>
          </Marker>
          {property && property.facilities && (
            <>
              {property.facilities.map(({ location, name }: any) => (
                <Marker
                  position={[location.lat, location.lon]}
                  icon={L.icon({
                    iconUrl:
                      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
                    iconRetinaUrl:
                      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
                    iconSize: new L.Point(25, 35),
                  })}
                >
                  <Popup>{name}</Popup>
                </Marker>
              ))}
            </>
          )}
        </MapContainer>
      ),
      []
    )

    return (
      <div>
        <Box mb={4} ref={scrollEndpoint}>
          {displayMap}
        </Box>
        {map && property && (
          <PropertySpot
            map={map}
            property={property}
            scrollToMap={scrollToMap}
          />
        )}
        {map && property && property.facilities && (
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={6}
            mt={8}
          >
            {property.facilities.map((facility: any, index: any) => (
              <FacilitySpot
                map={map}
                facility={facility}
                property={property}
                scrollToMap={scrollToMap}
              />
            ))}
          </Grid>
        )}
      </div>
    )
  }
  return null
}
