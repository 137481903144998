interface Spot {
  lat: number
  lng: number
}

const R = Math.PI / 180

export const getDistanceBetween2Points = (spotFrom: Spot, spotTo: Spot) => {
  const latFrom = spotFrom.lat * R
  const latTo = spotTo.lat * R
  const lngFrom = spotFrom.lng * R
  const lngTo = spotTo.lng * R

  const dLat = latTo - latFrom
  const dLng = lngTo - lngFrom

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(latFrom) *
      Math.cos(latTo) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = 6371 * c

  return d
}
