import React, { useState } from "react"
import { graphql } from "gatsby"

import {
  Container,
  Heading,
  Stack,
  Image,
  Wrap,
  WrapItem,
  Box,
  Text,
  Grid,
  GridItem,
  Tag,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Link,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from "framer-motion"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineShareButton,
  LineIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"
import { AiFillExclamationCircle } from "react-icons/ai"
import { useLocation } from "@reach/router"
import { AnimatedUnderlineButton } from "../components/animated-under-line-button"

import Layout from "../components/layout"
import { Map } from "../components/map"
import Seo from "../components/seo"
import dayjs from "dayjs"
import ja from "dayjs/locale/ja"
dayjs.locale(ja)

const PropertyDetail: React.FC<any> = ({ data }) => {
  const [pickImage, setPickImage] = useState(
    data.allContentfulProperties.edges[0]?.node?.images[0]
  )
  const { node: property } = data.allContentfulProperties.edges[0] || {
    node: null,
  }
  const { name, tel } = data.contentfulCompanyProfile
  const location = useLocation()
  const url = location.href
  const title = property?.propertyName

  const propertyNameBgColor = "blackAlpha.50"
  const categoryTagColor = "white"
  const categoryTagBgColor = "black"
  const newTagColor = "white"
  const newTagBgColor = "red.500"
  const kakakuHenkouTagColor = "red.500"
  const kakakuHenkouTagBgColor = "red.50"
  const shoudanTagColor = "cyan.500"
  const shoudanTagBgColor = "cyan.50"
  const seiyakuTagColor = "white"
  const seiyakuTagBgColor = "cyan.500"
  const tagColor = "black"
  const tagBgColor = "blackAlpha.50"
  const onePointColor = "white"
  const onePointBgColor = "blackAlpha.500"
  const headingColor = "white"
  const headingBgColor = "blackAlpha.500"
  const listColor = "blackAlpha.800"
  const listBgColor = "blackAlpha.50"

  return (
    <Layout>
      {property && (
        <>
          <Seo
            title={property?.propertyName}
            description={property.metaDescription.metaDescription}
            image={property.images[0].file.url}
          />
          <Box h={`10vh`} backgroundSize="cover"></Box>
          <Container maxW="container.xl" as={`main`} p={0}>
            <Stack
              bg={propertyNameBgColor}
              py={{ base: 4, lg: 12 }}
              px={{ base: 4, lg: 12 }}
            >
              <Stack direction="row">
                <Heading fontSize={{ base: 20, lg: 28 }}>
                  {property.propertyName}
                </Heading>
                <Tag
                  variant="solid"
                  bg={categoryTagBgColor}
                  color={categoryTagColor}
                  rounded={0}
                >
                  {property.propertyCategory.name}
                </Tag>
                {property.status === 2 && (
                  <Tag
                    variant="solid"
                    fontWeight={`bold`}
                    bg={newTagBgColor}
                    color={newTagColor}
                    rounded={0}
                  >
                    新着物件
                  </Tag>
                )}
                {property.status === 3 && (
                  <Tag
                    variant="solid"
                    fontWeight={`bold`}
                    bg={kakakuHenkouTagBgColor}
                    color={kakakuHenkouTagColor}
                    rounded={0}
                  >
                    価格変更
                  </Tag>
                )}
                {property.status === 7 && (
                  <Tag
                    variant="solid"
                    fontWeight={`bold`}
                    bg={shoudanTagBgColor}
                    color={shoudanTagColor}
                    rounded={0}
                  >
                    商談中
                  </Tag>
                )}
                {property.status === 8 && (
                  <Tag
                    variant="solid"
                    fontWeight={`bold`}
                    bg={seiyakuTagBgColor}
                    color={seiyakuTagColor}
                    rounded={0}
                  >
                    ご成約
                  </Tag>
                )}
              </Stack>

              <Text fontSize={{ base: 12, lg: 14 }}>
                {property.comment.comment}
              </Text>
            </Stack>
            <Stack
              p={{ base: 4, lg: 12 }}
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: 0, lg: 8 }}
            >
              <Stack w={{ base: "100%", lg: `70%` }}>
                <AnimatePresence exitBeforeEnter>
                  <Box
                    as={motion.div}
                    animate={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: 20 }}
                    exit={{ opacity: 0, y: -20 }}
                  >
                    <Image
                      src={pickImage.file.url}
                      alt={property.propertyName}
                      w={`100%`}
                      h={`500px`}
                      objectFit={`cover`}
                    />
                  </Box>
                </AnimatePresence>

                <Wrap
                  style={{
                    flexWrap: "wrap",
                    maxHeight: "175px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {property.images.map((image: any, index: number) => (
                    <WrapItem>
                      <button onClick={() => setPickImage(image)}>
                        <Image
                          src={image.file.url}
                          alt={`${property.propertyName}-${index}`}
                          h={{ base: "60px", md: "80px" }}
                          w={{ base: "105px", md: "135px" }}
                          opacity={image.id === pickImage.id ? 1 : 0.5}
                          objectFit={`cover`}
                        />
                      </button>
                    </WrapItem>
                  ))}
                </Wrap>

                <Stack py={8} spacing={4}>
                  <Wrap>
                    {property.tags &&
                      property.tags.map((tag: any) => (
                        <WrapItem>
                          <Tag
                            fontSize={12}
                            fontWeight={`bold`}
                            bg={tagBgColor}
                            color={tagColor}
                            rounded={0}
                          >
                            #{tag.name}
                          </Tag>
                        </WrapItem>
                      ))}
                  </Wrap>
                  <Box
                    bg={onePointBgColor}
                    py={{ base: 6, lg: 8 }}
                    px={{ base: 4, lg: 8 }}
                    pt={6}
                    mt={8}
                  >
                    <Heading
                      fontWeight={`bold`}
                      fontSize={{ base: 12, lg: 16 }}
                      color={onePointColor}
                      mb={4}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justify="start"
                        align="center"
                      >
                        <AiFillExclamationCircle size={20} />
                        <Text>ワンポイント</Text>
                      </Stack>
                    </Heading>
                    <Text
                      as={`pre`}
                      whiteSpace="pre-wrap"
                      color={onePointColor}
                      fontSize={{ base: 12, lg: 14 }}
                      lineHeight={1.8}
                    >
                      {property.onePoint.onePoint}
                    </Text>
                  </Box>
                  <Table
                    variant="unstyled"
                    overflowWrap={`break-word`}
                    fontSize={{ base: 12, lg: 14 }}
                  >
                    <Tbody>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={headingBgColor}
                          color={headingColor}
                          fontWeight={`bold`}
                        >
                          交通
                        </Td>
                        <Td colSpan={3}>{property.transportation}</Td>
                      </Tr>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          所在地
                        </Td>
                        <Td>{property.adress}</Td>
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          地目
                        </Td>
                        <Td>{property.landCategory}</Td>
                      </Tr>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          土地権利
                        </Td>
                        <Td>{property.landOwnership}</Td>
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          地勢
                        </Td>
                        <Td>{property.terrain}</Td>
                      </Tr>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          現況
                        </Td>
                        <Td>{property.occupancy}</Td>
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          引渡し
                        </Td>
                        <Td>{property.handover}</Td>
                      </Tr>
                      {property.buildingSpaceSqm && (
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            駐車場
                          </Td>
                          <Td>{property.parking}</Td>
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            接道状況
                          </Td>
                          <Td>{property.adjacentRoad}</Td>
                        </Tr>
                      )}
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          取引様態
                        </Td>
                        <Td>{property.conditionsTransactions}</Td>
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          物件番号
                        </Td>
                        <Td>{property.propertyCode}</Td>
                      </Tr>
                      {property.buildingSpaceSqm && (
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            設備
                          </Td>
                          <Td colSpan={3}>{property.utilities}</Td>
                        </Tr>
                      )}
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          備考
                        </Td>
                        {property.remark && (
                          <Td colSpan={3}>
                            <Text as={`pre`} whiteSpace="pre-wrap">
                              {property.remark.remark}
                            </Text>
                          </Td>
                        )}
                      </Tr>
                    </Tbody>
                  </Table>
                  <Table
                    variant="unstyled"
                    overflowWrap={`break-word`}
                    fontSize={{ base: 12, lg: 14 }}
                  >
                    <Tbody>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={headingBgColor}
                          color={headingColor}
                          fontWeight={`bold`}
                        >
                          土地面積
                        </Td>
                        <Td>
                          <p>
                            <b>{property.landSpaceSqm}㎡</b>
                          </p>{" "}
                          / <br />
                          {Math.round(
                            (property.landSpaceSqm / 3.306) * Math.pow(10, 2)
                          ) / Math.pow(10, 2)}
                          坪
                        </Td>
                      </Tr>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          都市計画
                        </Td>
                        <Td colSpan={3} overflowWrap={`break-word`}>
                          {property.cityPlanningArea}
                        </Td>
                      </Tr>
                      <Tr
                        borderTop={`1px`}
                        borderBottom={`1px`}
                        borderColor={`gray.200`}
                      >
                        <Td
                          bg={listBgColor}
                          color={listColor}
                          fontWeight={`bold`}
                        >
                          用途地域
                        </Td>
                        <Td colSpan={3} overflowWrap={`break-word`}>
                          {property.useZoning}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  {property.buildingSpaceSqm && (
                    <Table
                      variant="unstyled"
                      overflowWrap={`break-word`}
                      fontSize={14}
                    >
                      <Tbody>
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={headingBgColor}
                            color={headingColor}
                            fontWeight={`bold`}
                          >
                            建物面積
                          </Td>
                          <Td>
                            <p>
                              <b>{property.buildingSpaceSqm}㎡</b>
                            </p>{" "}
                            / <br />
                            {Math.round(
                              (property.buildingSpaceSqm / 3.306) *
                                Math.pow(10, 2)
                            ) / Math.pow(10, 2)}
                            坪
                          </Td>
                        </Tr>
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            間取り
                          </Td>
                          <Td colSpan={3} overflowWrap={`break-word`}>
                            {property.layout}
                          </Td>
                        </Tr>
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            築年月
                          </Td>
                          <Td colSpan={3} overflowWrap={`break-word`}>
                            {dayjs(property.completion).format("YYYY年MMMM")}
                          </Td>
                        </Tr>
                        <Tr
                          borderTop={`1px`}
                          borderBottom={`1px`}
                          borderColor={`gray.200`}
                        >
                          <Td
                            bg={listBgColor}
                            color={listColor}
                            fontWeight={`bold`}
                          >
                            構造・規模
                          </Td>
                          <Td colSpan={3} overflowWrap={`break-word`}>
                            {property.structure}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  )}
                </Stack>
              </Stack>
              <Stack
                w={{ base: "100%", lg: `30%` }}
                h={`650px`}
                spacing={8}
                pt={8}
                sx={{
                  position: "sticky",
                  top: "0",
                }}
              >
                <Heading
                  w={`100%`}
                  fontSize={18}
                  borderBottom={`1px`}
                  pb={2}
                  borderColor={`gray.200`}
                >
                  物件詳細
                </Heading>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} w={`80%`}>
                  {property.status < 7 && (
                    <GridItem w="100%">
                      <Stack spacing={2} justify={`center`} align="center">
                        <StaticImage
                          src="../images/money.png"
                          alt="A dinosaur"
                          width={80}
                          height={80}
                          layout={`fixed`}
                        />
                        <Text>{property.price}万円</Text>
                      </Stack>
                    </GridItem>
                  )}
                  <GridItem w="100%">
                    <Stack spacing={2} justify={`center`} align="center">
                      <StaticImage
                        src="../images/architecture-and-city.png"
                        alt="A dinosaur"
                        width={80}
                        height={80}
                        layout={`fixed`}
                      />
                      <Text>{property.landSpaceSqm}㎡</Text>
                    </Stack>
                  </GridItem>
                  {property.bedroom && (
                    <GridItem w="100%">
                      <Stack spacing={2} justify={`center`} align="center">
                        <StaticImage
                          src="../images/bed.png"
                          alt="A dinosaur"
                          width={80}
                          height={80}
                          layout={`fixed`}
                        />
                        <Text>{property.bedroom} ベッドルーム</Text>
                      </Stack>
                    </GridItem>
                  )}
                  {property.bathroom && (
                    <GridItem w="100%">
                      <Stack spacing={2} justify={`center`} align="center">
                        <StaticImage
                          src="../images/bath.png"
                          alt="A dinosaur"
                          width={80}
                          height={80}
                          layout={`fixed`}
                        />
                        <Text>{property.bathroom} バスルーム</Text>
                      </Stack>
                    </GridItem>
                  )}
                </Grid>
                <Divider />
                <Stack border={`2px`} borderColor={`white`} spacing={0}>
                  <Heading
                    w={`100%`}
                    fontSize={18}
                    borderBottom={`1px`}
                    pb={2}
                    borderColor={`gray.200`}
                  >
                    お問い合わせ
                  </Heading>
                  <Flex
                    justify="center"
                    align="center"
                    direction={`column`}
                    pt={4}
                    pb={8}
                  >
                    <Text mt={2} mb={4} fontSize={14}>
                      お気軽にご相談ください
                    </Text>
                    <Text
                      color={"blackAlpha.500"}
                      fontSize={12}
                      fontWeight={"thin"}
                    >
                      {name}
                    </Text>
                    <Text mb={2} fontSize={24} fontWeight={`bold`}>
                      <Link href="tel:+81-558-64-8880">{tel}</Link>
                    </Text>
                    <AnimatedUnderlineButton
                      text={"この物件について問い合わせる"}
                      fontSize={16}
                      url={"/contact"}
                    ></AnimatedUnderlineButton>
                  </Flex>
                </Stack>
                <Heading
                  w={`100%`}
                  fontSize={18}
                  borderBottom={`1px`}
                  pb={2}
                  borderColor={`gray.200`}
                >
                  SNSでシェアする
                </Heading>
                <Stack direction="row" spacing={4}>
                  <FacebookShareButton url={url} title={title}>
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={url} title={title}>
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  <LineShareButton url={url} title={title}>
                    <LineIcon size={40} round />
                  </LineShareButton>
                  <EmailShareButton url={url} title={title}>
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                </Stack>
              </Stack>
            </Stack>
            <Box px={{ base: 4, lg: 12 }} pb={{ base: 8, lg: 24 }}>
              <Heading fontWeight="normal" fontSize={24} mb={8}>
                周辺施設
              </Heading>
              <Map property={property} />
            </Box>
          </Container>
        </>
      )}
    </Layout>
  )
}

export default PropertyDetail

export const query = graphql`
  query ($slug: String!) {
    contentfulCompanyProfile {
      name
      tel
    }
    allContentfulProperties(
      filter: { status: { ne: 9 }, slug: { eq: $slug } }
      sort: { order: DESC, fields: updatedAt }
    ) {
      edges {
        node {
          id
          propertyCode
          propertyName
          metaDescription {
            metaDescription
          }
          propertyCategory {
            name
          }
          area {
            name
          }
          facilities {
            id
            name
            description {
              description
            }
            location {
              lat
              lon
            }
            featuredPhoto {
              file {
                url
              }
            }
          }
          status
          price
          adress
          nearestStation
          transportation
          layout
          layoutDetail
          structure
          bedroom
          bathroom
          landSpaceSqm
          buildingSpaceSqm
          completion
          landOwnership
          landCategory
          cityPlanningArea
          useZoning
          otherLegalRestriction
          buildingCoverageRatio
          floorAreaRatio
          terrain
          adjacentRoad
          parking
          utilities
          occupancy
          handover
          conditionsTransactions
          images {
            id
            file {
              url
            }
          }
          tags {
            name
          }
          propertyLocation {
            lat
            lon
          }
          comment {
            id
            comment
          }
          onePoint {
            id
            onePoint
          }
          remark {
            id
            remark
          }
          pic
          createdAt
          updatedAt
        }
      }
    }
  }
`
